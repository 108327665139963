import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ThemeModule } from 'libs/infrastructure/theme/theme.module';
import { ImmomioTheme } from 'libs/infrastructure/theme/themes/immomioTheme';
import { ThemeUrls } from 'libs/infrastructure/theme/theme-urls';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  InfrastructureConfig,
  InfrastructureModule,
  FreshchatWidgetService
} from 'libs/infrastructure';
import { DateTimeService } from 'libs/services';
import {
  TranslateFakeLoader,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { ComponentsModule } from 'libs/components';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LightboxModule } from 'ng-gallery/lightbox';
import { CoreModule } from 'homepage/core';
import { environment } from 'homepage-env';
import { AppComponent } from './app.component';
import { AppRouterModule } from './app.router';
import { storageKeys, languageConfig } from './config';
import { appReducers, effects } from './+state';
import { PropertiesComponent } from './screens/properties/properties.component';
import { PropertiesCardListComponent } from './screens/properties/components/properties-card-list/properties-card-list.component';
import { PropertiesFilterComponent } from './screens/properties/components/properties-filter/properties-filter.component';
import { PropertiesMapComponent } from './screens/properties/components/properties-map/properties-map.component';
import { PropertiesTableComponent } from './screens/properties/components/properties-table/properties-table.component';

const infrastructureConfig: InfrastructureConfig = {
  environment,
  storageKeys,
  languages: languageConfig
};

const declarations = [
  AppComponent,
  PropertiesComponent,
  PropertiesCardListComponent,
  PropertiesFilterComponent,
  PropertiesMapComponent
];

const imports = [
  CoreModule,
  BrowserModule,
  AppRouterModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  InfrastructureModule,
  BrowserAnimationsModule,
  StoreModule.forRoot(appReducers),
  EffectsModule.forRoot(effects),
  InfrastructureModule.forRoot(infrastructureConfig),
  TranslateModule.forRoot({
    loader: { provide: TranslateLoader, useClass: TranslateFakeLoader }
  }),
  ComponentsModule,
  LightboxModule,
  AngularSvgIconModule.forRoot(),
  ThemeModule.forRoot({
    themes: [ImmomioTheme],
    active: ThemeUrls.IMMOMIO
  })
];

const providers = [
  FreshchatWidgetService,
  DateTimeService

  /* SVG test injection: */
  // SvgTestService
];

@NgModule({
  declarations: [declarations, PropertiesTableComponent],
  imports: [imports],
  providers: [providers],
  bootstrap: [AppComponent]
})
export class AppModule {}
