import { NgModule, Optional, SkipSelf } from '@angular/core';

import { environment } from 'homepage-env';
import { ENVIRONMENT_CONFIG } from './environment';

const providers = [{ provide: ENVIRONMENT_CONFIG, useValue: environment }];

@NgModule({
  providers
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `${String(
          parentModule
        )} has already been loaded. Import core module in the AppModule only.`
      );
    }
  }
}
