<div class="properties-filter pb-3">
  <form [formGroup]="filterForm">
    <div class="row pb-3">
      <div class="col-12 col-lg-9 col-md-6"></div>
      <div class="col-12 col-lg-3 col-md-6">
        <app-button [type]="'primary'" (clickEvent)="resetForm()"
          ><span> {{'general.filter_reset' | translate}}</span>
        </app-button>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-12 col-lg-3 col-md-6 order-md-1 order-lg-1">
        <app-dropdown-select
          [items]="typesOfUse"
          formControlName="typeOfUse"
          (ngModelChange)="onFilterChange()"
        >
          <div dropdown-button>{{ typesOfUse[0].name | translate }}</div>
          <ng-template let-item>{{item.name | translate}}</ng-template>
        </app-dropdown-select>
      </div>
      <ng-container *ngIf="showLocationSearchContainer">
        <div
          class="col-12 col-lg-3 py-3 py-md-0 py-lg-0 col-md-6 order-md-3 order-lg-2 pt-md-3"
        >
          <app-form-field *ngIf="showLocationSearch">
            <app-location-search-field
              formControlName="location"
              [appendCountryToSearch]="true"
              [country]="'DE'"
              (locationData)="onLocationSelect($event)"
            ></app-location-search-field>
          </app-form-field>
        </div>
        <div
          class="col-12 col-lg-3 col-md-6 order-md-4 order-lg-3 pt-md-3 pt-lg-0"
        >
          <app-dropdown-select
            *ngIf="showLocationSearch"
            [items]="radiusOptions"
            formControlName="radius"
            [disabled]="!selectedLocation"
          >
            <ng-template let-item>{{ item.name | translate }}</ng-template>
          </app-dropdown-select>
        </div>
      </ng-container>
      <div
        class="col-lg-3 col-md-6 col-12 pt-3 pt-md-0 pt-lg-0 properties-filter__search-id order-md-2 order-lg-4"
      >
        <app-form-field>
          <input
            appInput
            type="text"
            minlength="1"
            maxlength="50"
            appAutofocus
            [placeholder]="'general.search_ID' | translate"
            class="form-control"
            formControlName="searchByID"
          />
        </app-form-field>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-12 col-lg-3 col-md-6 pb-2">
        <app-form-field
          [label]="(isTypeFlat ? 'general.living_space_min' :
            'general.usable_area_min') | translate"
          [disabledClickArea]="true"
        >
          <app-slider
            [min]="0"
            [max]="200"
            [step]="5"
            [unit]="'&#13217;'"
            [unitMax]="'&#13217;'"
            (change)="onFilterChange()"
            formControlName="propertySize"
          >
          </app-slider>
        </app-form-field>
      </div>
      <div class="col-12 col-lg-3 col-md-6 pb-2">
        <app-form-field
          [label]="'general.number_of_rooms_min' | translate"
          [disabledClickArea]="true"
          [disabled]="disableNumberOfRoomsFilter"
        >
          <app-slider
            [min]="0.0"
            [max]="9.0"
            [step]="1"
            formControlName="numberOfRooms"
            (change)="onFilterChange()"
            [hideActiveValue]="disableNumberOfRoomsFilter"
            [disabled]="disableNumberOfRoomsFilter"
          >
          </app-slider>
        </app-form-field>
      </div>
      <div class="col-12 col-lg-3 col-md-6 pb-2">
        <app-form-field
          [label]="'general.total_rent_max' | translate"
          [disabledClickArea]="true"
        >
          <app-slider
            [min]="0"
            [max]="2500"
            [step]="10"
            [unit]="'€'"
            [unitMax]="'€ +'"
            (change)="onFilterChange()"
            formControlName="totalRentGross"
          >
          </app-slider>
        </app-form-field>
      </div>
      <div class="col-12 col-lg-3 col-md-6">
        <app-form-field
          [label]="'general.floor_max' | translate"
          [disabledClickArea]="true"
          [disabled]="disableFloorFilter"
        >
          <app-slider
            [min]="0"
            [max]="20"
            [step]="1"
            [unitMax]="'+'"
            formControlName="floor"
            (change)="onFilterChange()"
            [hideActiveValue]="disableFloorFilter"
            [disabled]="disableFloorFilter"
          >
          </app-slider>
        </app-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-9 col-md-6 d-flex align-items-center">
        <ul class="properties-filter__list">
          <li>
            <app-form-field>
              <app-checkbox
                [label]="'general.apartment_barrier_free_l' | translate"
                formControlName="barrierFree"
                (change)="onFilterChange()"
                [hideActiveValue]="disableBarrierFreeFilter"
                [disabled]="disableBarrierFreeFilter"
              ></app-checkbox>
            </app-form-field>
          </li>
          <li>
            <app-form-field>
              <app-checkbox
                [label]="'general.balcony_or_terrace_l' | translate"
                formControlName="balconyTerrace"
                (change)="onFilterChange()"
                [hideActiveValue]="disableTerraceBalconyFilter"
                [disabled]="disableTerraceBalconyFilter"
              ></app-checkbox>
            </app-form-field>
          </li>
          <li>
            <app-form-field>
              <app-checkbox
                [label]="'general.wbs' | translate"
                formControlName="wbs"
                (change)="onFilterChange()"
                [hideActiveValue]="disableHousingPermissionFilter"
                [disabled]="disableHousingPermissionFilter"
              ></app-checkbox>
            </app-form-field>
          </li>
        </ul>
      </div>
      <div
        class="col-12 col-lg-3 col-md-6 pt-3 pt-lg-0 pt-md-0"
        *ngIf="!isMapView"
      >
        <app-dropdown-select
          [items]="sortOptions"
          formControlName="sortOption"
          (ngModelChange)="onFilterChange()"
        >
          <ng-template let-item>{{ item.name | translate }}</ng-template>
        </app-dropdown-select>
      </div>
      <div class="col-12">
        <div class="endFilterSection pt-lg-3 pt-md-3 pt-2"></div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-lg-3 col-md-6 col-12">
      <div class="row">
        <div class="col-lg-6 col-6 pt-3 pe-0">
          <app-button
            [type]="isMapView ? 'context-menu' : 'secondary'"
            [elevation]="1"
            [borderRadius]="'none'"
            (clickEvent)="changeView(false)"
            [iconLeft]="'list'"
          >
            <span> {{'general.list_view' | translate}}</span>
          </app-button>
        </div>
        <div class="col-lg-6 col-6 pt-3 ps-0">
          <app-button
            [type]="!isMapView ? 'context-menu' : 'secondary'"
            [elevation]="1"
            [borderRadius]="'none'"
            [iconLeft]="'location'"
            (clickEvent)="changeView(true)"
          >
            <span> {{'general.map_view' | translate}}</span>
          </app-button>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-3" *ngIf="options?.type === 'CARD'">
    <div class="col-12">
      <span class="semibold">
        <span class="icon icon--search me-1"></span>
        {{pagination?.totalElements || 0}} {{'general.results' |
        translate}}</span
      >
    </div>
  </div>
</div>
