import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import {
  locationSearchQuery,
  LocationSearchResult
} from 'libs/queries/location-search.queries';
import {
  HomepagePropertySearch,
  homepagePropertySearchQuery,
  HomepageOptions,
  homepageOptionsQuery
} from '../gql-queries';
import * as PropertyListActions from './property-list.actions';

@Injectable()
export class PropertyListEffects {
  constructor(
    private actions$: Actions,
    private apollo: Apollo
  ) {}
  searchProperties$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PropertyListActions.searchProperties),
      mergeMap(({ propertySearchInput }) =>
        this.apollo
          .query<HomepagePropertySearch>({
            query: homepagePropertySearchQuery,
            variables: {
              input: propertySearchInput
            }
          })
          .pipe(
            map(output => {
              const payload = {
                propertySearchOutput: output.data.propertyList.nodes,
                pagination: output.data.propertyList.page
              };
              return propertySearchInput.page === 0
                ? PropertyListActions.searchPropertiesSuccess(payload)
                : PropertyListActions.updateProperties(payload);
            }),
            catchError(error =>
              of(PropertyListActions.searchPropertiesFailure({ error }))
            )
          )
      )
    );
  });

  getHomepageOptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PropertyListActions.getHomepageOptions),
      mergeMap(({ token }) =>
        this.apollo
          .query<HomepageOptions>({
            query: homepageOptionsQuery,
            variables: {
              input: token
            }
          })
          .pipe(
            map(output => {
              const payload = {
                homepageOptionsResponse: output.data.getOptions
              };
              return PropertyListActions.getHomepageOptionsSuccess(payload);
            }),
            catchError(error =>
              of(PropertyListActions.getHomepageOptionsFailure({ error }))
            )
          )
      )
    );
  });

  locationSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PropertyListActions.locationSearch),
      mergeMap(({ name }) =>
        this.apollo
          .query<LocationSearchResult>({
            query: locationSearchQuery,
            variables: {
              input: name
            }
          })
          .pipe(
            map(output => {
              const payload = {
                locationSearchResultEntries:
                  output.data.searchLocations.features
              };
              return PropertyListActions.locationSearchSuccess(payload);
            }),
            catchError(error =>
              of(PropertyListActions.locationSearchFailure({ error }))
            )
          )
      )
    );
  });

  setTileServerUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PropertyListActions.setTileServerUrl),
      map(({ tileServerUrl }) => {
        return PropertyListActions.setTileServerUrlSuccess({ tileServerUrl });
      })
    );
  });
}
