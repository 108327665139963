import { HomepageOptionsResponse } from 'homepage/models';
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';
import { Pagination } from '@ui/shared/models';
import { LocationSearchResultEntries } from 'libs/queries/location-search.queries';
import { HomepagePropertySearchResponse } from 'homepage/models';
import * as PropertyListActions from './property-list.actions';

export interface PropertyListState {
  propertyList: HomepagePropertySearchResponse[];
  pagination: Pagination;
  options: HomepageOptionsResponse;
  error: string;
  isLoading: boolean;
  locations: LocationSearchResultEntries;
  mapRadiusDrawInfo: [number, number, number];
  tileServerUrl: string;
}

const initialState: PropertyListState = {
  propertyList: null,
  pagination: null,
  options: null,
  error: '',
  isLoading: false,
  locations: null,
  mapRadiusDrawInfo: null,
  tileServerUrl: ''
};

const getPropertyListFeatureState =
  createFeatureSelector<PropertyListState>('propertyList');

export const getPropertySearchOutput = createSelector(
  getPropertyListFeatureState,
  state => state.propertyList
);

export const getError = createSelector(
  getPropertyListFeatureState,
  state => state.error
);

export const getPropertyPagination = createSelector(
  getPropertyListFeatureState,
  state => state.pagination
);

export const getLoadingStatus = createSelector(
  getPropertyListFeatureState,
  state => state.isLoading
);

export const getOptions = createSelector(
  getPropertyListFeatureState,
  state => state.options
);

export const getLocations = createSelector(
  getPropertyListFeatureState,
  state => state.locations
);

export const getMapRadiusDrawInfo = createSelector(
  getPropertyListFeatureState,
  state => state.mapRadiusDrawInfo
);

export const getTileServerUrl = createSelector(
  getPropertyListFeatureState,
  state => state.tileServerUrl
);

export const propertyListReducers = createReducer<PropertyListState>(
  initialState,
  on(PropertyListActions.searchProperties, (state): PropertyListState => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(
    PropertyListActions.searchPropertiesSuccess,
    (state, action): PropertyListState => {
      return {
        ...state,
        propertyList: action.propertySearchOutput,
        pagination: action.pagination,
        error: '',
        isLoading: false
      };
    }
  ),
  on(
    PropertyListActions.searchPropertiesFailure,
    (state, action): PropertyListState => {
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    }
  ),
  on(
    PropertyListActions.updateProperties,
    (state, action): PropertyListState => {
      return {
        ...state,
        propertyList: state.propertyList.concat(action.propertySearchOutput),
        pagination: action.pagination,
        isLoading: false
      };
    }
  ),
  on(PropertyListActions.getHomepageOptions, (state): PropertyListState => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(
    PropertyListActions.getHomepageOptionsSuccess,
    (state, action): PropertyListState => {
      return {
        ...state,
        isLoading: false,
        options: action.homepageOptionsResponse
      };
    }
  ),
  on(
    PropertyListActions.getHomepageOptionsFailure,
    (state, action): PropertyListState => ({
      ...state,
      isLoading: false,
      error: action.error
    })
  ),
  on(PropertyListActions.locationSearch, (state): PropertyListState => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(
    PropertyListActions.locationSearchSuccess,
    (state, action): PropertyListState => {
      return {
        ...state,
        isLoading: false,
        locations: action.locationSearchResultEntries
      };
    }
  ),
  on(
    PropertyListActions.locationSearchFailure,
    (state, action): PropertyListState => ({
      ...state,
      isLoading: false,
      error: action.error
    })
  ),
  on(
    PropertyListActions.drawMapRadius,
    (state, action): PropertyListState => ({
      ...state,
      mapRadiusDrawInfo: action.mapRadiusDrawInfo
    })
  ),
  on(
    PropertyListActions.setTileServerUrl,
    (state, action): PropertyListState => ({
      ...state,
      tileServerUrl: action.tileServerUrl
    })
  )
);

export function reducer(state: PropertyListState | undefined, action: Action) {
  return propertyListReducers(state, action);
}
