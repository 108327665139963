<div class="properties">
  <div class="properties__top">
    <app-properties-filter
      (form)="sortChanges($event)"
      [pagination]="pagination$ | async"
      [isMapView]="isMapView"
      (isMap)="viewChanges($event)"
      [options]="options"
    ></app-properties-filter>
    <app-properties-card-list
      *ngIf="isCardView && (properties$ | async) && (properties$ | async)?.length"
      [properties]="properties$ | async"
      [pagination]="pagination$ | async"
      [isLoading]="isLoading$ | async"
      (intersect)="getProperties($event)"
    >
    </app-properties-card-list>
    <ng-container *ngIf="isListView && !isMapView">
      <app-properties-table
        [properties]="properties$ | async"
        [options]="options"
        [customerId]="customerId"
      >
      </app-properties-table>
    </ng-container>
  </div>
  <app-properties-map
    *ngIf="isMapView && (properties$ | async)"
    [properties]="properties$ | async"
  ></app-properties-map>
  <div
    class="loading-area"
    *ngIf="(isLoading$ | async) && !(properties$ | async)?.length "
  >
    <app-loading-spinner></app-loading-spinner>
  </div>

  <div
    class="py-5"
    *ngIf="isCardView && !(properties$ | async)?.length && (isLoading$ | async) === false"
  >
    <app-no-data-disclaimer
      [title]="'general.no_properties_headline'"
      [description]="'general.no_properties'"
    ></app-no-data-disclaimer>
  </div>
</div>
