import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HomepagePropertySearchResponse } from 'homepage/models';
import { MapComponent } from 'libs/components/molecules/map/map.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as fromPropertyListState from 'homepage/+state/property-list/property-list.reducers';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { MapRadiusDrawInfo } from 'libs/components/molecules/map/map.model';

@UntilDestroy()
@Component({
  selector: 'app-properties-map',
  templateUrl: './properties-map.component.html',
  styleUrls: ['./properties-map.component.scss']
})
export class PropertiesMapComponent implements OnInit {
  @ViewChild(MapComponent) map: MapComponent;
  @Input() properties: HomepagePropertySearchResponse[];
  public tileServerUrl$: Observable<string>;

  public mapRadiusDrawInfo: MapRadiusDrawInfo;

  constructor(private store: Store<fromBaseState.AppState>) {}

  ngOnInit(): void {
    this.store
      .select(fromPropertyListState.getMapRadiusDrawInfo)
      .pipe(untilDestroyed(this))
      .subscribe(drawInfo => {
        this.mapRadiusDrawInfo = drawInfo;
      });

    this.tileServerUrl$ = this.store.select(
      fromPropertyListState.getTileServerUrl
    );
  }

  public openApplyPage(applicationLink: string) {
    window.open(applicationLink, '_blank');
  }
}
