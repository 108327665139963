export const propertiesTableSvgConfig = {
  querySelector: {
    container: '.properties-table__svg-container',
    building: '.svg-building',
    level: '.svg-level',
    property: '.svg-property'
  },
  class: {
    hiddenBuilding: 'svg-building--hide',
    elevatedLevel: 'svg-level--elevate',
    activeProperty: 'svg-property--active',
    propertyStatus: {
      rented: 'svg-property--status-rented',
      reserved: 'svg-property--status-reserved',
      free: 'svg-property--status-free',
      custom: 'svg-property--status-custom'
    }
  },
  attr: {
    propertyId: 'id',
    svgLink: 'data-svg-id'
  }
};
