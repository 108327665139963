import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { Component, ViewContainerRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18nService, PageTitleService } from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { fadeOnEnterLeaveAnimation } from 'libs/utils';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ToastService } from 'libs/components/legacy/toast';
import * as fromPropertyListState from 'homepage/+state/property-list/property-list.reducers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'homepage-env';
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeOnEnterLeaveAnimation]
})
export class AppComponent implements AfterViewInit {
  public finishedLoaded$: Observable<boolean>;
  public constructor(
    public toastService: ToastService,
    public viewContainerRef: ViewContainerRef,
    private pageTitleService: PageTitleService,
    private store: Store<fromBaseState.AppState>,
    private activatedRoute: ActivatedRoute,
    private i18nService: I18nService,
    private themeService: ThemeService,
    private translate: TranslateService
  ) {
    this.pageTitleService.run(this.activatedRoute);
    this.i18nService.loadDefaultTranslations();
    this.toastService.setRootViewContainerRef(viewContainerRef);
    this.finishedLoaded$ = this.store.select(
      fromBaseState.getTranslationLoaded
    );
    this.themeService.init(environment);
  }

  ngAfterViewInit(): void {
    this.store
      .select(fromPropertyListState.getError)
      .pipe(untilDestroyed(this))
      .subscribe(error => {
        if (error) {
          this.toastService.error(
            this.translate.instant('general.unexpected_error_occurred_m')
          );
        }
      });
    this.store
      .select(fromPropertyListState.getOptions)
      .pipe(untilDestroyed(this))
      .subscribe(options => {
        if (options) {
          const theme = options.branding.theme;
          const activeTheme = {
            logo: options.branding.logo,
            favicon: options.branding.favicon,
            theme: {
              ...theme
            }
          };
          this.themeService.createTheme(activeTheme);
          this.themeService.setTheme(activeTheme.theme.name);
        }
      });
  }
}
