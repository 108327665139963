import { ActionReducerMap } from '@ngrx/store';
import * as fromPropertiesReducer from './property-list/property-list.reducers';

export interface AppState {
  propertyList: fromPropertiesReducer.PropertyListState;
}

export const appReducers: ActionReducerMap<AppState> = {
  propertyList: fromPropertiesReducer.reducer
};
