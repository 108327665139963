import {
  AvailableFrom,
  FilterRange,
  Attachment,
  PropertyType,
  Address
} from '@ui/shared/models';

export interface HomepagePropertySearchRequest {
  page: number;
  size: number;
  token: string;
  sort?: string[];
  floor?: FilterRange;
  roomNumber?: FilterRange;
  propertySize?: FilterRange;
  wbs?: boolean;
  barrierFree?: boolean;
  balconyOrTerrace?: boolean;
  totalRentGross?: FilterRange;
  propertyType?: string;
  propertyId?: number;
  city?: string;
  area?: Area;
  offset?: number;
  pageNumber?: number;
  pageSize?: number;
  paged?: boolean;
  unpaged?: boolean;
  externalId?: string;
}

export interface Area {
  coordinates: CoordsInput;
  radius: number;
}

export interface CoordsInput {
  lat: number;
  lon: number;
}

export interface HomepagePropertySearchResponse {
  address: Address;
  showAddress: boolean;
  name: string;
  totalRooms: number;
  availableFrom: AvailableFrom;
  titleImage: Attachment;
  size: number;
  totalRentGross: number;
  bathRooms: number;
  objectId: number;
  propertyType: PropertyType;
  wbs: boolean;
  applicationLink: string;
  externalId: string;
  floor: number;
  status: HomepagePropertyStatusType;
}

export enum HomepagePropertyStatusType {
  IMPORTED = 'IMPORTED',
  RESERVED = 'RESERVED',
  RENTED = 'RENTED',
  DEFAULT = 'DEFAULT'
}

export const homepageSortings = {
  totalRentGrossAsc: 'data->>totalRentGross,asc',
  totalRentGrossDesc: 'data->>totalRentGross,desc',
  createdAsc: 'created,asc',
  createdDesc: 'created,desc',
  propertySizeAsc: 'data->>size,asc',
  propertySizeDesc: 'data->>size,desc',
  numberOfRoomsAsc: 'data->>totalRooms,asc',
  numberOfRoomsDesc: 'data->>totalRooms,desc',
  availableFromAsc: 'data->>availableFrom->>dateAvailable,asc',
  availableFromDesc: 'data->>availableFrom->>dateAvailable,desc'
};
